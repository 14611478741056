.wrapper {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  left: 127px;
  top: 17px;
}

:global(.bp3-spinner) {
  transform: scale(0.5);
}
