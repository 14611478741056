.deleteFoldersModal {
  position: absolute;
  top: 50%;
  z-index: 50;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 23px 20px 0 20px;
  box-shadow: 0px 0px 12px rgb(125 125 125 / 70%);
  background: #fff;
  border-radius: 5px;
  width: 485px;
  min-height: 225px;
  display: flex;
  flex-direction: column;
}

.deleteFoldersModal h1 {
  margin-bottom: 18px;
}

.footerButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  top: -8px;
  margin-top: auto;
}
