.shortcutSyncModal {
  position: absolute;
  top: 50%;
  z-index: 50;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 23px 20px 0 20px;
  box-shadow: 0px 0px 12px rgb(125 125 125 / 70%);
  background: #fff;
  border-radius: 5px;
  width: 600px;
  min-height: 420px;
  display: flex;
  flex-direction: column;
}

.footerButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: -8px;
  margin-top: auto;
}

.buttonGroup {
  display: flex;
  align-items: center;
}

.grid > *:not(:last-child) {
  margin-bottom: 15px;
}

.close {
  position: absolute;
  top: 12px;
  right: 12px;
}

.check {
}

.check svg {
  width: 60px;
  height: 60px;
}

.confirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 105px;
}

.confirm p {
  margin-top: 32px;
  width: 347px;
  text-align: center;
}

.figmaGuide {
  width: 100%;
  margin-bottom: 14px;
}

.graphic {
  text-align: center;
  padding-top: 50px;
}

.graphic img {
  width: 220px;
}
