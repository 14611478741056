.members {
}

.membersHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table {
  margin-top: 0;
  border: 1px solid #e5e5e5;
  border-bottom: none;
  border-radius: 5px;
  box-shadow: var(--el-shadow2);
}

.tableHeader {
  font-weight: bold;
  background: hsl(230deg 17% 95%);
  color: hsl(230deg 17% 36%);
  min-height: 50px;
}

.tableBody {
}

.row {
  border-bottom: 1px solid hsl(230deg 17% 94%);
}

.tableBody .row:nth-child(even) {
  background: hsl(230deg 17% 98%);
}

.tableHeader,
.row {
  display: grid;
  grid: auto / 61px 225px 1fr auto;
  padding: 10px;
  align-items: center;
}

.tableHeader button,
.row button {
  min-height: 27px;
}

.me {
  font-weight: bold;
}
