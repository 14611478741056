.tippy-box[data-theme="ellio"] {
  background-color: transparent;
  /*box-shadow: var(--el-shadow);*/
  /*min-width: 200px;
  min-height: 100px;
  border-radius: 6px;*/
}

.my-menu {
  background: white;
  box-shadow: var(--el-shadow);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  padding: 6px;
  position: relative;
  top: -5px;
}

.menu-row {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
}

.menu-row button {
  height: 100%;
  padding: 11px 26px 11px 15px;
}

.menu-row:hover {
  background-color: #e5e6e7;
}

.menu-row.danger {
  margin-top: 9px;
  border-top: 1px solid #f1f1f1;
  border-radius: 0 0 3px 3px;
}

.menu-row.danger:hover {
  background: rgba(239, 44, 44, 1);
}

.menu-row.danger:hover .menu-row-text,
.menu-row.danger:hover .menu-row-text button {
  color: white;
}

.menu-row.danger:hover .menu-row-icon path {
  fill: white;
}

.menu-row:not(:last-child) {
  /*margin-bottom: 11px;*/
}

.menu-row-icon {
  margin-right: 8px;
}

.menu-row-icon svg {
  width: 20px;
  height: 20px;
  /*position: relative;
  left: 5px;*/
}

.menu-row-icon div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-row-text {
  width: 100%;
  height: 100%;
}

.menu-row-text,
.menu-row-text button {
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #7d83a4;
}

.menu-row-text button:hover {
  color: hsl(231deg 18% 37%);
}
