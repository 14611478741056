/* base html */

html {
  font-size: 16px;
}

html,
body {
  /* font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu;*/

  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button {
  /*font-family: IBM Plex Sans;*/
}

body,
html,
#root {
  height: 100%;
}

body {
  overflow: hidden;
}

body {
  --el-gap-smallest: 4px;
  --el-gap-small: 8px;
  --el-gap1: 12px;
  --el-gap: 16px;
  --el-gap2: 20px;
  --el-gap3: 30px;

  --el-shadow: 0px 0px 12px rgba(207, 219, 234, 0.7);
  --el-shadow2: 0px 0px 12px hsl(213deg 39% 86% / 34%);
  --el-animation: cubic-bezier(0.31, 0.59, 0.07, 1);
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
p,
li {
  margin: 0;
  padding: 0;
}

button {
  background: transparent;
  border: none;
}

button,
button:hover {
  transition: all 200ms ease-in-out;
  transition-property: opacity, transform;
}

button:hover {
  cursor: pointer;
  transform: translateY(-2px);
  opacity: 0.9;
}

button:focus,
button:active {
  outline: 0;
}

button[disabled],
button[disabled]:hover {
  cursor: not-allowed;
  opacity: 0.5;
}

button[disabled]:hover {
  transform: translateX(0);
}

.mb {
  margin-bottom: 12px;
}

.mb2 {
  margin-bottom: 20px;
}

.mt {
  margin-top: 12px;
}

.mt2 {
  margin-top: 24px;
}

.mr {
  margin-right: 12px;
}
.upper {
  text-transform: uppercase;
}

.rel {
  position: relative;
}

.abs {
  position: absolute;
}

.border-bottom {
  border-bottom: 1px solid #e5e6e7;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.clearfix {
  content: "";
  display: table;
  clear: both;
}

.float {
  float: left;
}

.hide.hide {
  display: none;
}

/* helpers */

.el-flex {
  display: flex;
}

.el-flex-btw {
  display: flex;
  justify-content: space-between;
}

.el-vc {
  display: flex;
  align-items: center;
}

.el-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-text-center {
  text-align: center;
}

.el-flex-g1 > *:not(:last-child) {
  margin-right: var(--el-gap1);
}

.el-flex-items {
  display: flex;
}
.el-flex-items > * {
  flex: 1;
}

.el-grid {
  display: grid;
}

.el-grid.--two-col {
  grid: auto/ 1fr 1fr;
}

.el-grid.--gap1 {
  grid-column-gap: var(--el-gap1);
}

.el-abs {
  position: absolute;
}

.page-loading {
  height: 100vh;
  display: flex;
  padding-top: 342px;
  padding-left: 30px;
  justify-content: center;
  align-items: flex-start;
}

/* spinner */
@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.el-spinner {
  position: relative;
  opacity: 1;
  transition: opacity linear 50ms;
  width: 25px;
  height: 25px;
}

.el-spinner:before {
  animation: 0.5s linear infinite spinner;
  border: solid 2px hsl(212deg 100% 50% / 12%);
  border-bottom-color: #0075ff;
  border-radius: 50%;
  content: "";
  width: 25px;
  height: 25px;
  left: 50%;
  opacity: inherit;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  transform-origin: center;

  will-change: transform;
}

/* blueprint spinner override */
.bp3-spinner.light-spinner .bp3-spinner-track {
  stroke: rgb(255 255 255 / 23%);
}

.bp3-spinner.light-spinner .bp3-spinner-head {
  stroke: #fff;
}

.bp3-spinner.ellio-spinner .bp3-spinner-track {
  stroke: rgb(0 117 255 / 20%);
}

.bp3-spinner.ellio-spinner .bp3-spinner-head {
  stroke: #0075ff;
}

/* colors */
body {
  --dark-navy: rgba(125, 131, 164, 1);
}

.upload-progress {
  position: absolute;
  top: 0;
  right: 0;
  width: 500px;
  background: white;
  z-index: 10;
}

.circle-progress svg {
  height: 50px;
}

.profileImage {
  background-size: cover;
  border-radius: 50%;
  background-position: center;
}
