@value h1 from "common/type.module.css";

.folders {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(352px, 1fr));
  grid-gap: 20px;
  margin-bottom: 50px;
  position: relative;
  left: 0;
  margin-top: 19px;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(313px, 1fr));
  grid-column-gap: 25px;
  grid-row-gap: 35px;
  margin: 20px 0;
}

.search {
  padding: 30px 17px;
  border-bottom: 1px solid #d5e3f0;
  margin-bottom: 27px;
}

.extraOptions {
  display: flex;
  justify-content: flex-end;
}
