@value h2, ctrl2 from "common/type.module.css";
.value {
  composes: h2;
  text-align: center;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
}
