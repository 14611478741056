.title {
  color: #7d83a4;
  margin-bottom: 10px;
  font-weight: 500;
}

.toggle {
  margin-top: 20px;
}

.toggle button {
  transform: translate(0);
  color: hsl(231deg 18% 60%);
}

.options {
  display: flex;
  align-items: center;
  background: #f9f9f9;
  justify-content: center;
  padding: 5px;
  width: fit-content;
  border-radius: 6px;
  border: 1px solid #f4f4f4;
}

.options > *:first-child {
  margin-right: 4px;
}

.option {
  transition: all 150ms ease-in-out;
}

.option:not(.active):hover {
  background: #f3f3f3;
  border-radius: 4px;
}

.active {
  background: #ececec;
  border-radius: 4px;
}

.option,
.active {
  padding: 4px 8px;
}

.active button {
  color: hsl(231deg 18% 45%);
}
