@value h1, p3, ctrl4 from "common/type.module.css";
@value primary from "common/button.module.css";

.settings {
  height: 100%;
}

.inner {
  display: grid;
  grid: auto/ 253px 1fr;
  height: calc(100% - 52px);
}

.nav {
  height: 100%;
  background: white;
  left: 98px;
  padding: 0;
}

.navList {
  padding: 11px 24px 0 7px;
  height: 100%;
  left: 97px;
  top: 160px;
  position: fixed;
  width: 253px;
}

.navList::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border-right: 1px solid #d5e3f0;
}

.navList li {
  display: flex;
  align-items: center;
  padding-left: 14px;
  /*border-top: 1px solid #f2f2f2;*/
  border-radius: 0;
}

.navList li:first-child {
  border-top: 0;
}

.link {
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: 600;
  font-size: 14.5px;
  line-height: 18px;
  color: #7d83a4;
  flex: 1;
  padding: 14px 5px 14px 0;
}

button.link {
  position: relative;
  left: -16px;
}

.link span {
  position: absolute;
  right: -13px;
  top: 15px;
}

.link:hover {
  text-decoration: none;
  color: #525252;
  transform: translate(0);
}

.navList .navActive .link {
  color: #525252;
}

.navActive.navActive {
  box-shadow: var(--el-shadow);
  border-radius: 8px;
  border-top: 0;
  background: #fff;
  position: relative;
  transform: translateX(10px);
  z-index: 9;
  transition: all 500ms var(--el-animation);
}

.navActive + li {
  border-top: 0;
}

.icon svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.icon div {
  display: flex;
  align-items: center;
}

.navList path {
  fill: #7d83a4;
}

.navActive path {
  fill: #525252;
}

.navActive .invIcon.invIcon {
  color: #525252;
}

.invIcon {
  color: #7d83a4;
}

.invIcon.invIcon path {
  fill: #fff;
}

.section {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 20px;
  padding-bottom: 12px;
}

.sectionTitle {
  composes: ctrl4;
  color: #7d83a4;
  font-size: 16px;
}

.profileWrapper {
  padding: 20px;
}

.profile {
  position: relative;
  display: grid;
  grid: auto / 400px 1fr;
  grid-column-gap: 30px;
  padding: 0;
}

.profile h2 {
  margin-bottom: 12px;
}

.profileBg {
  box-shadow: var(--el-shadow);
  border-radius: 8px;
  padding: 18px 22px;
  background: #ffffff73;
  backdrop-filter: blur(9px);
}

.profile {
}

.profileEdit {
  composes: profileBg;
}

.profileUsage {
  composes: profileBg;
}

.usage {
  display: grid;
  grid: auto/1fr 1fr;
  grid-column-gap: 40px;
}

.usageRow {
  composes: p3;
  position: relative;
  margin-bottom: 0;
  font-size: 12px;
}

.usageRow p {
  position: relative;
  top: 5px;
}

.usageProgress {
  height: 26px;
  width: 100%;
}

.profile button {
}

.updateProfileButton {
  composes: primary;
  min-width: 120px;
  text-align: center;
  justify-content: center;
}

.avatar {
  width: 120px;
  margin: auto;
  position: relative;
}

.avatar svg {
  width: 80px;
}

.picture {
  position: absolute;
  right: 0;
  bottom: 0;
}

.picture img {
  width: 36px;
  height: 36px;
}

.profileInputs {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.profileInputs input {
  margin-bottom: 20px;
}

.section {
  background-image: url("./shield-bg.png");
  background-size: cover;
  background-position: 22vw 28.6vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.section.sectionNoBgImage {
  background-image: none;
}

.plans {
  padding: 20px;
}

.plansHeader {
  composes: sectionHeader;
}

.plansBillingPeriod,
.plansBillingPeriod label {
  display: flex;
  align-items: center;
}

.plansBillingPeriod {
  margin-right: 8px;
  margin-top: 12px;
}

.plansBillingToggle {
  composes: p3;
  position: relative;
  top: 3px;
  min-width: 58px;
}

.plansBillingPeriod .react-switch-bg {
  background: #e5e6e7;
}

.sub {
  width: 400px;
}

.chevron {
  margin-right: 5px;
}
