.dashboard {
  background: white;
  height: 100%;
  display: grid;
  grid: 65px 43px 1fr/97px 1fr;
  overflow: hidden;
  min-width: 1400px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px 0 24px;
  grid-area: 1/1/2/3;
  background: #fff;
}

.headerLeft {
  display: flex;
  align-items: center;
  position: relative;
}

.notificationsMenu {
  margin-right: 15px;
}

div.notificationsInner {
  min-width: 400px;
  min-height: 472px;
  position: relative;
  right: 32px;
  top: -5px;
  color: black;
  padding: 0;
}

.notificationsHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d5e3f0;
  padding-bottom: 18px;
  padding: 25px;
  margin: 0 4px;
}

.notificationsHeader h2 {
}

.notifyBody {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.notifyBody img {
  width: 100px;
}

.notifyList {
  overflow: auto;
  max-height: 400px;
  padding-bottom: 15px;
}

.notifyRow {
  position: relative;
  border-bottom: 1px solid hsl(209deg 47% 97%);
  padding-bottom: 16px;
  padding: 23px 25px;
  margin: 0 14px;
}

.notifyDismiss {
  position: absolute;
  top: 24px;
  right: 8px;
}

.notifyDismiss img {
  width: 15px;
}

.notifyRow h2 {
  color: hsl(231deg 18% 40%);
  font-size: 14px;
}

.notifyRow time {
  font-size: 12px;
}

.notifyEmpty {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.notifyEmpty svg {
  width: 40px;
  height: 40px;
}

.headerRight {
  display: flex;
  align-items: center;
}

.headerRight > input {
  margin-right: 24px;
}

.headerRight > button {
  margin-right: 14px;
}

.beta {
  display: block;
  position: absolute;
  left: 81px;
  top: -8px;
  text-transform: uppercase;
  background: #0275ff;
  border-radius: 10em;
  color: #fff;
  padding: 2px 7px;
  font-size: 8px;
}

.logo {
  height: 25px;
}

.logo,
.logo:hover {
  transition: all 200ms ease-in-out;
  transition-property: opacity;
}

.logo:hover {
  opacity: 0.7;
  cursor: pointer;
}

.profile {
  width: 58px;
  height: 58px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile,
.profile:hover {
  transition: all 200ms ease-in-out;
  transition-property: opacity, transform;
}

.profile:hover {
  opacity: 0.9;
  transform: translateY(-3px);
  cursor: pointer;
}

.breadcrumb {
  grid-area: 2/1/3/3;
  background: #f1f6f9;
  border: 1px solid #d5e3f0;
  border-width: 1px 0 1px;
  padding: 0 30px;
  display: flex;
  align-items: center;
}

.nav {
  width: 97px;
  grid-area: 3/1/4/2;
  background: #f1f6f9;
  border-right: 1px solid #d5e3f0;
}

.main {
  width: 100%;
  grid-area: 3/2/4/3;
  background: #fff;
  overflow: auto;
  /*padding-bottom: 30px;*/
}

.bellCircle {
  position: absolute;
  font-size: 11px;
  top: -8px;
  right: -7px;
  width: 20px;
  height: 20px;
  background: #9452a5;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: 1;
  border-radius: 50%;
}

.feedbackWrapper {
}

.feedback {
  position: fixed;
  bottom: 26px;
  right: 26px;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  box-shadow: var(--el-shadow);
  background: #fff;
  z-index: 99;
}

.feedback div {
  display: flex;
  align-items: center;
}

.feedback svg {
  width: 25px;
  height: 25px;
}

.feedback path {
  fill: #7d83a4;
}

.upgradeButton {
  background-color: #0075ff;
  border-radius: 3rem;
  color: hsl(212deg 100% 98%);
  padding: 10px 21px;
  font-size: 11px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.upgradeButton svg,
.upgradeButton path {
  fill: currentColor;
}

.upgradeButton2 {
  background-color: #f1f6f9;
  border-radius: 7px;
  color: hsl(202deg 40% 40%);
  padding: 11px 28px;
  font-size: 11px;
  text-transform: uppercase;
  border: 1px solid #d5e3f0;
}

.profileImage {
  width: 45px;
  height: 45px;
  background-size: cover;
  border-radius: 50%;
  background-position: center;
}

.logoutTop {
  position: absolute;
  z-index: 5;
  top: 8px;
  right: 8px;
}

.readOnly {
  position: fixed;
  top: 8px;
  width: 100%;
  height: 50px;
  background: #f1947e;
  color: #fff;
  z-index: 1;
  text-align: center;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
