/* eg: no designs */
:global(body) {
  color: #4a515d;
}
.h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 29.25px;
  line-height: 35px;
}

/* design preview name */
.h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

/* eg:  comment text */
.p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.pSm {
  composes: p;
  font-size: 11px;
}

.pMb {
  composes: p;
  margin-bottom: 8px;
}

.pMb2 {
  composes: p;
  margin-bottom: 12px;
}

/* eg:  you dont have any designs */
.p1 {
  font-style: normal;
  font-weight: normal;
  font-size: 19.5px;
  line-height: 29px;
}

/* eg:  breadcrum */
.p2 {
  font-style: normal;
  font-weight: normal;
  font-size: 14.6268px;
  line-height: 17px;
}

/* eg:  hint text */
.p3 {
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #7d83a4;
}

/* eg:  drag designs or close */
.ctrl {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.ctrlDarkNavy {
  composes: ctrl;
  color: var(--dark-navy);
}

/* 2 days ago */
.ctrl2 {
  /* font-family: IBM Plex Sans; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

/* eg button text */
.ctrl3 {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 29px;
}

/* eg:  hints */
.ctrl4 {
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

/* eg:  modal for new folder or invite title */
.ctrl5 {
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}
