.folder {
  position: relative;
  width: 100%;
  padding: 25px 15px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e5e6e7;
  box-shadow: var(--el-shadow2);
  border-radius: 12px;
  background-color: #fff;
}

.name.name {
  width: 192px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  text-align: center;
}

.newFolder {
  width: 100px;
  height: 96px;
  border-radius: 6px;
  align-items: center;
  margin-top: 12px;
  justify-content: center;
}

.newFolder button {
  width: 100%;
  height: 80%;
}

.newFolder,
.newFolder:hover {
  transition: all 250ms ease-in-out;
  transition-property: transform;
}

.newFolder:hover {
  cursor: pointer;
  transform: translateY(-3px);
}

.header {
}

.folder h2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.folder time {
  text-align: center;
  display: block;
}

.select,
.check {
  position: absolute;
  left: 3px;
  top: 5px;
}

.select circle {
  stroke: #0075ff;
}

.more,
.more div,
.more svg {
  display: block;
  width: 20px;
  height: 20px;
}

.extras {
  position: absolute;
  right: 5px;
  top: 7px;
  min-height: 0;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body {
  margin-top: 0;
}
