@value p from "common/type.module.css";

.input {
  composes: p;
  min-width: 257px;
  background-color: #eaf1f7;
  padding: 11px 18px;
  font-size: 17px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 6px;
  color: #5a6474;
}

.input:active,
.input:focus {
  outline: 0;
  border: 1px solid gray;
}

.input::placeholder {
  color: #7d83a4;
}

.textInput {
  composes: p;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  min-width: 320px;
  height: 36px;
  background: #fff;
  border: 1px solid #e1e3e6;
  box-sizing: border-box;
  border-radius: 8px;
}

.textInput::placeholder {
  color: #c2c2c2;
}

.textInput[disabled] {
  opacity: 0.5;
}

.textInput[disabled]:focus,
.textInput[readonly]:focus {
  border: 1px solid #e5e6e7;
  outline: none;
}

.textInput[readonly] {
  color: gray;
  cursor: not-allowed;
}

.textInput:active,
.textInput:focus {
  outline: 0;
  border: 1px solid gray;
}

.editable,
.editable:hover {
  transition: all 150ms ease-in-out;
  transition-property: box-shadow, transform;
}

.editable:hover {
  transform: translateY(-2px);
}

.editable:active,
.editable:focus {
  outline: 0;
}

.editable:hover,
.editable:active,
.editable:focus {
  box-shadow: 0 1px 0px #e5e6e7;
}

.textarea {
  width: 100%;
  resize: none;
  border-radius: 6px;
  border: 1px solid #e1e3e6;
  padding: 12px 16px;
  line-height: 1.4;
}

.textarea:focus {
  outline: 0;
  border: 1px solid gray;
}
