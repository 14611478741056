.teamModal {
  position: absolute;
  top: 50%;
  z-index: 50;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 23px 20px 0 20px;
  box-shadow: 0px 0px 12px rgb(125 125 125 / 70%);
  background: #fff;
  border-radius: 5px;
  width: 600px;
  min-height: 485px;
  display: flex;
  flex-direction: column;
}

.teamModal h1 {
  margin-bottom: 18px;
}

.footerButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  top: -8px;
  margin-top: auto;
}

.orgDetails {
  margin-top: 30px;
}

.orgName {
  width: 210px;
  margin: auto;
  min-width: 5px;
  margin-top: 20px;
}

.theImage {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.logoPlaceholder {
  width: 90px;
  height: 90px;
  position: relative;
  border: 1px solid;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e3e3e3;
}

.logoPlaceholder svg,
.logoPlaceholder img {
  width: 34px;
  height: 34px;
  object-fit: contain;
  color: hsl(229deg 17% 92%);
}

.selectImage {
  margin-top: 8px;
  border: 1px solid #e5e6e7;
  border-radius: 5rem;
  padding: 3px 11px;
  color: #7d83a4;
  font-size: 11px;
  min-height: 1px;
}
