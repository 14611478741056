@value h1, p1 from "common/type.module.css";
@value primaryLg from "common/button.module.css";

.noContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.title {
  composes: h1;
  margin-bottom: 10px;
  margin-top: 190px;
  z-index: 5;
}

.text {
  composes: p1;
  color: #7d83a4;
  width: 561px;
  margin-bottom: 40px;
  z-index: 5;
}

.button {
  composes: primaryLg;
  z-index: 5;
}

.clouds {
  width: 1034px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 303px;
}

.cloud {
  position: absolute;
}

.cloudLeft {
  composes: cloud;
  width: 407.06px;
  height: 199.88px;
  left: 31px;
  top: 214.84px;
}

.cloudRight {
  composes: cloud;
  width: 438.75px;
  height: 215.72px;
  right: 50px;
  top: 83.12px;
}

.uploadProgress {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% + 40px), -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.uploadProgress button {
  position: relative;
  top: -10px;
}

.inner {
  padding: 20px;
}

.multiSelectOptions {
  position: fixed;
  top: 107px;
  left: 0;
  transform: translateY(-5px);
  background: #fff;
  box-shadow: var(--el-shadow);
  height: 76px;
  border-bottom: 1px solid #d5e3f0;
  z-index: 9999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 250ms ease-in-out;
  user-select: none;
  pointer-events: none;
}

.multiSelectOptionsVisible {
  transform: translateY(0);
  opacity: 1;
  z-index: 9999;
  pointer-events: auto;
}

.addToFolder div {
  display: flex;
}

.addToFolder svg {
  width: 31px;
  height: 31px;
}

.addToFolder:hover path {
  fill: #0075ff;
}

.deleteSelected div {
  display: flex;
}

.deleteSelected svg {
  width: 31px;
  height: 31px;
}

.deleteSelected:hover path {
  fill: #0075ff;
}

.deselect:hover path {
  fill: #0075ff;
}

.deselect div {
  display: flex;
}

.deselect svg {
  width: 20px;
  height: 20px;
}

.deselect:hover circle {
  stroke: #0075ff;
}

.newFolder div {
  display: flex;
}

.newFolder svg {
  width: 30px;
  height: 30px;
}

.newFolder:hover path {
  fill: #0075ff;
}

.addCollaborators div {
  display: flex;
}

.addCollaborators svg {
  width: 24px;
  height: 24px;
}

.addCollaborators:hover path {
  fill: #0075ff;
}

.showTips div {
  display: flex;
}

.showTips svg {
  width: 24px;
  height: 24px;
}

.showTips:hover path {
  fill: #0075ff;
}

.folderOptions {
}

.folderOptionsInner {
  display: flex;
  margin-bottom: 25px;
}

.noImages {
  text-align: center;
  width: 500px;
  margin: auto;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noImages button {
  margin-top: 20px;
}

.noImages img {
  height: 220px;
  margin-bottom: 25px;
}

.tipsButton {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.bigAdd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 75px;
}

.bigAdd svg {
  width: 70px;
  height: 70px;
}

.extraOptions {
  display: flex;
}

.figmaIcon svg {
  width: 24px;
  height: 24px;
}
