.wrapper {
}

.items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item {
  /* i couldnt import p3 from type :( wasnt working */

  font-style: normal;
  font-weight: normal;
  font-size: 14.6268px;
  line-height: 17px;
  /* */
  display: flex;
  align-items: center;
  color: #7d83a4;
  opacity: 0.5;
}

.item:hover {
  opacity: 1;
  cursor: pointer;
}

.item,
.item:hover {
  transition: all 250ms ease-in-out;
  transition-property: opacity;
}

.active {
  opacity: 1;
}

.line {
  margin: 0 12px;
}

.goTo {
}
