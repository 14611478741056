.blurOverlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgb(121 121 121 / 50%);
  backdrop-filter: blur(5px);
  z-index: 199;
  opacity: 0;
  transition: all 250ms ease-in-out;
}

.fadeIn {
  opacity: 1;
}
