@value h2, p, p1, p2, p3, ctrl2 from "common/type.module.css";

.list {
  display: flex;
  text-align: center;
  position: relative;
  z-index: 9;
  margin-top: 30px;
}

.plans {
  padding-bottom: 20px;
}

.image {
  text-align: center;
  height: 250px;
  display: flex;
  align-items: center;
  display: none;
}

.image img {
  width: 100%;
  height: 86%;
  object-fit: contain;
  margin-bottom: 10px;
}

.card {
  width: 345px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-radius: 8px;
  box-shadow: var(--el-shadow);
  background: hsl(0deg 0% 100% / 91%);
}

.card:not(:last-child) {
  margin-right: 14px;
}

.cardActive {
  background: linear-gradient(
      180deg,
      #ffffff 18.14%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(135deg, #72edf2 38.33%, #5151e5 100%);
}

.cardActive .description {
  color: black;
}

.name {
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  margin-bottom: 15px;
}

.price {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  text-align: center;
}

.details {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.priceValue {
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: normal;
  font-size: 44px;
  line-height: 57px;
  color: #0075ff;
  position: relative;
  top: 2px;
}

.priceNote {
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #545454;
  margin-left: 10px;
  text-align: left;
  line-height: 1.2;
}

.description {
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #7d83a4;
  margin-bottom: 20px;
  margin-top: 0;
}

.button {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: auto;
  padding: 0;
}

.button button {
  text-align: center;
  display: block;
  margin-top: 25px;
  width: 100%;
}

.planRow {
  display: grid;
  grid: auto/auto 1fr;
  text-align: left;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 6px auto;
  grid-column-gap: 11px;
}

.planRow.planRowMute {
  color: hsl(223deg 21% 75%);
}

.activePlan {
}

.activePlanInner {
  display: grid;
  grid: auto / 500px 1fr;
  grid-column-gap: 50px;
  margin-top: 20px;
}

.activePlanInnerCancel {
  margin-top: 20px;
}

.activePlanCard {
  width: 100%;
  box-shadow: var(--el-shadow);
  padding: 30px 0 30px 30px;
  border-radius: 6px;
  background: #fff;
  position: relative;
  margin-top: 25px;
}

.activePlanCardH2.activePlanCardH2 {
  margin-bottom: 0;
}

.activePlanTitle {
  text-transform: uppercase;

  display: flex;
  align-items: center;
}

.planInterval {
  composes: p3;
  margin-bottom: 11px;
}

.activePlanImage {
  position: absolute;
  right: 0;
}

.activePlanImage img {
  height: 226px;
  position: relative;
  top: -68px;
}

.activePlanImagePro {
}
.activePlanImagePro img {
  right: 22px;
}

.activePlanDetails {
  display: flex;
  justify-content: space-between;
}

.activePlanFeatures {
}

.activePlanMange {
  margin-top: 12px;
}

.activePlanMange button {
  min-width: 126px;
  justify-content: center;
}

.nextBillInfo {
}

.billingStatus {
  margin-top: 25px;
}

.nextBillInfo > * {
  margin-bottom: 8px;
  font-size: 14px;
}

.planStatus {
  color: #fff;
  padding: 1px 9px;
  border-radius: 3em;
  font-size: 9px;
  position: relative;
  margin-left: 8px;
  height: 21px;
  display: flex;
  text-transform: uppercase;
  align-items: center;
}

.planStatusOrange {
  composes: planStatus;
  background: #fbbb00;
}

.planStatusCanceled {
  composes: planStatus;
  background: #fbbb00;
}

.planStatusActive {
  composes: planStatus;
  background: #00bc44;
}

.BillingInfoCancel {
  width: 390px;
}

.trialInfo {
  width: 390px;
}

.defaultText {
  width: 100%;
  font-size: 15px;
  background: #fff;
  padding: 18px;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
}

.activePlanSeats {
  font-size: 13px;
  display: flex;
  margin-bottom: 17px;
  color: #7d83a4;
  font-weight: normal;
}
