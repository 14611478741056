.ajmtoaster.theme-light,
.ajmtoaster.theme-default {
  --ajmt-white: #fff;
  --ajmt-success: #00bc44;
  --ajmt-failure: #f43c3c;
  --ajmt-warning: #fca549;
  --ajmt-info: blue;
  /* transition properties */
  --ajmt-duration: 850ms;
  --ajmt-easing: cubic-bezier(0.31, 0.59, 0.07, 1);
}

.ajmtoaster.--animation-slide-left,
.ajmtoaster.--animation-slide-left.--active {
  transition: transform var(--ajmt-duration) var(--ajmt-easing) 0ms,
    opacity var(--ajmt-duration) var(--ajmt-easing) 50ms;
}

.ajmtoaster.--animation-slide-left {
  opacity: 0;
  transform: translateX(100px);
}

.ajmtoaster.--animation-slide-left.--active {
  opacity: 1;
  transform: translateX(0);
}

.ajmtoaster.theme-light {
  top: 15px;
  right: 15px;
}

.ajmtoaster__inner.theme-light {
  box-shadow: var(--el-shadow);
  border-radius: 6px;
  padding: 13px 23px 16px;
  min-height: 100px;
}

.ajmtoaster.theme-light {
  width: 350px;
}

.ajmtoaster__inner.theme-light.--success {
  border-left: 6px solid var(--ajmt-success);
  background: white;
}

.ajmtoaster__inner.theme-light.--failure {
  background: white;
  border-left: 6px solid var(--ajmt-failure);
}

.ajmtoaster__inner.theme-light.--warning {
  border-left: 6px solid var(--ajmt-warning);
  background: white;
}

.ajmtoaster__inner.theme-light {
  color: #7d83a4;
}

.ajmtoaster__dismiss.theme-light {
  background: transparent;
  color: currentColor;
  top: 15px;
  right: 4px;
  height: auto;
  font-size: 20px;
}

.ajmtoaster__dismiss.theme-light:hover {
  transform: translateY(-11px);
}

.ajmtoaster__dismiss.theme-light,
.ajmtoaster__dismiss.theme-light:hover {
  transition: all 200ms ease-in-out;
  transition-property: transform;
}

.ajmtoaster__dismiss.theme-light:active {
  background: transparent;
}

.ajmtoaster__title.theme-light {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  /* identical to box height, or 24px */
}

.ajmtoaster__message.with-message.theme-light {
  margin-top: 0;
}
