@value p1 from 'common/type.module.css';
.wrapper {
  position: absolute;
  width: 300px;
  height: 200px;
  box-shadow: var(--el-shadow);
  border-radius: 12px;
}

.comment {
  padding: 0;
  height: 100%;
  background: rgb(255 255 255 / 99%);
  overflow: hidden;
  font-size: 16px;
  border-radius: 12px;
}

.textArea {
  composes: p1;
  font-size: 16px;
  color: #4a515d;
  overflow: auto;
  min-height: 100%;
  width: 100%;
  border: none;
  resize: none;
  background: rgb(255 255 255 / 98%);
  padding: 5px;
  padding-left: 12px;
}

.textArea:focus {
  outline: 0;
}

.textArea[disabled] {
  color: gray;
}

.textArea[disabled]:hover {
  cursor: not-allowed;
}
