@value h2, ctrl3, p from "common/type.module.css";

.button {
  cursor: pointer;
  composes: h2;
  background: transparent;
  display: flex;
  align-items: center;
  min-height: 37px;
}

.button[disabled] {
  opacity: 0.5;
}

.button:active,
.button:focus {
  outline: 0;
  /*border: 1px solid gray;*/
}

.buttonSm {
  composes: button;
  composes: p;
  font-size: 12px;
  font-weight: 400;
  min-height: 5px;
  width: 100%;
}

.buttonSm:hover {
  transform: translateY(0);
}

.withIcon {
  composes: button;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
}

.withIcon > img {
  margin-left: 5px;
}

.svgIcon div {
  display: flex;
  align-items: center;
}

.svgIcon svg {
  height: 15px;
  width: 15px;
  margin-left: 5px;
}

.primary {
  composes: button;
  border: none;
  border-radius: 6px;
  background: #0075ff;
  color: white;
  padding: 6px 16px;
  min-height: 47px;
  font-weight: 500;
}

.primary:active {
  opacity: 1;
}

.primaryLg {
  composes: primary;
  composes: ctrl3;
  padding: 14px 47px;
}

.primarySm {
  composes: primary;
  padding: 1px 14px;
  min-height: 35px;
  font-size: 12px;
}

.primary:hover {
  color: white;
  text-decoration: none;
  transform: translateY(-2px);
}

.primary,
.primary:hover {
  transition: all 200ms ease-in-out;
}

.dangerSm {
  composes: primarySm;
  background: #ef2c2c;
}

.dangerText {
  composes: dangerSm;
  background: transparent;
  color: #ef2c2c;
}

.dangerText:hover {
  color: #ef2c2c;
}

.dangerTextSmall {
  composes: dangerText;
  font-size: 14px;
}

.primaryText {
  composes: primary;
  color: #0075ff;
  background: transparent;
}

.primaryText:hover {
  color: #0075ff;
}

.primaryTextSmall {
  composes: primaryText;
  font-size: 14px;
}

.success {
  composes: primary;
  background: #00bc44;
}

.outline {
  composes: button;
  composes: h2;
  line-height: 24px;
  background: transparent;
  border: 1px solid white;
  border-radius: 6px;
  color: white;
  padding: 7px 22px;
}

.outlineSmall {
  composes: outline;
  padding: 4px 17px;
}

.outline:hover {
  background: white;
  color: #0075ff;
  border: 1px solid #0075ff;
}

.outlineInvert {
  composes: outline;
  color: #0075ff;
}

.outlineInvert:active {
  color: hsl(212deg 100% 60%);
}

.outlineSmallInvert {
  composes: outlineInvert;
  composes: outlineSmall;
  font-size: 14px;
}

.outlineSmallInvertNoOutline:hover {
  border: none;
}

.outlineActive,
.outlineActive:hover {
  background: #0075ff;
  color: #fff;
}

.iconOnly {
  composes: button;
  border: 1.2189px solid #d5e3f0;
  border-radius: 7px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

iconOnly > img {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

.link {
  display: flex;
  font-size: 12px;
  align-items: center;
  color: #0075ff;
  border: 1px solid;
  padding: 2px 8px;
  border-radius: 5px;
}

.linkSpinner {
  composes: link;
  min-height: 31px;
}

:global([class^="button_primary"] .el-spinner:before) {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-bottom-color: #fff;
}

:global([class^="button_danger"] .el-spinner:before) {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-bottom-color: #fff;
}

:global([class^="button_link"] .el-spinner:before) {
  width: 19px;
  height: 19px;
  border: solid 2px hsl(212deg 100% 50% / 12%);
  border-bottom-color: #0075ff;
  margin-left: 3px;
}

:global([class^="button_link"]:hover .el-spinner:before) {
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-bottom-color: #fff;
}

.link:hover {
  background: #0075ff;
  color: #fff;
  /*transform: translateY(0);*/
}

.link:active {
  opacity: 0.8;
}

.linkMd {
  composes: link;
  padding: 7px 14px;
}
