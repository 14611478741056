.nav {
  padding-top: 15px;
}

.navList {
  list-style-type: none;
  text-align: center;
}

.navList .navItem:not(:last-child) {
  margin-bottom: 30px;
}

.navItem {
  padding: 0;
}

.navLink {
  transition: all 200ms ease-in-out;
  transition-property: opacity;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  border-radius: 8px;
  margin-left: 25px;
}

.navLink.active {
  box-shadow: var(--el-shadow);
  background: white;
  transform: translateY(-3px);
}

.navLink,
.navLink:hover {
  transition: all 200ms ease-in-out;
  transition-property: opacity, background, box-shadow, transform;
}

.navLink:hover {
  background: white;
  box-shadow: var(--el-shadow);
  transform: translateY(-3px);
}

.logout {
  position: absolute;
  bottom: 17px;
}
