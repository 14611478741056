@value primary from "common/button.module.css";

.form {
  z-index: 1;
  position: relative;
  opacity: 0.95;
}

.danger {
}

.inputs {
  display: block;
  width: 448px;
  margin: 0 auto;
  border: 1px solid #d5e3f0;
  border-radius: 6px;
}

.inputWrapper {
  display: flex;
}

div .inputWrapper:last-child {
  border-bottom: none;
}

.unameLogo {
  border-top-left-radius: 6px;
}

.pwdLogo {
  border-bottom-left-radius: 6px;
}

.unameLogo,
.pwdLogo {
  width: 61px;
  background: #d5e3f0;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unameLogo img,
.pwdLogo img {
  width: 22px;
  height: 22px;
  object-fit: contain;
}

.input {
  display: block;
  flex: 1;
  border: none;
}

.input:focus,
.input:active {
  outline: 0;
}

.unameInput,
.pwdInput {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #7d83a4;
  padding: 22px 18px;
}

.unameInput {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.pwdInput {
  border-bottom-right-radius: 6px;
}

.buttonWrapper {
  text-align: center;
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.button {
  composes: primary;
  padding: 12px 43px;
}

.error {
  color: red;
  text-align: center;
  margin-bottom: 21px;
}

.eyeButton {
  background: white;
}

.eyeButton:hover {
  transform: translateY(0);
}
