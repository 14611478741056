.collaborators {
  display: flex;
  position: absolute;
  left: 8px;
  bottom: 9px;
  pointer-events: none;
}

.moreCollabs {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  position: relative;
  left: 4px;
  background: #d5e3f0;
  top: 0px;
  border: 1px solid #fff;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.profileImage {
  position: relative;
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 50%;
  background-position: center;
}
