.feedbackModal {
  position: absolute;
  top: 50%;
  z-index: 50;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 23px 20px 0 20px;
  box-shadow: 0px 0px 12px rgb(125 125 125 / 70%);
  background: #fff;
  border-radius: 5px;
  width: 670px;
  height: 421px;
  display: flex;
  flex-direction: column;
}

.feedbackModal h1 {
  margin-bottom: 18px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: -8px;
  margin-top: auto;
  padding-bottom: 15px;
}

.footerButtons {
  display: flex;
  align-items: center;
}

.inputs {
  flex: 1;
  margin-top: 17px;
  display: flex;
  flex-direction: column;
}

.inputs input {
  margin-bottom: 10px;
}

.inputs textarea {
  flex: 1;
  margin-bottom: 30px;
}

.attach {
  display: flex;
  align-items: center;
}

.attach span {
  margin-left: 5px;
}

.attach img {
  height: 20px;
}

.deleteAttach {
  display: flex;
  align-items: center;
  background: #f9f9f9;
  border-radius: 7px;
  padding: 2px;
  border: 1px solid #e6e6e6;
  height: 28px;
}

.deleteAttach button {
  color: gray;
  margin-left: 5px;
}

.fileSize {
  font-size: 11px;
  padding-left: 29px;
  position: relative;
  top: -11px;
}

.invalid {
  color: #ef2c2c;
}

.check {
}

.check svg {
  width: 60px;
  height: 60px;
}

.confirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 105px;
}

.confirm p {
  margin-top: 32px;
  width: 347px;
  text-align: center;
}

.spinner {
  position: absolute;
  left: 11px;
}

.fileButton {
  height: 48px;
  position: relative;
  top: -6px;
}
