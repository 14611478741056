@value h1 from "common/type.module.css";

.header {
  padding: 8px 17px;
  border-bottom: 1px solid #d5e3f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
  background: white;
  position: relative;
}

.title {
  composes: h1;
  font-size: 20px;
}

.info,
.info div {
  display: flex;
  align-items: center;
}

.info svg {
  width: 17px;
  height: 17px;
}
