.fileSelect {
  padding: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: transparent;
  border: 1px solid #d5e3f0;
  box-sizing: border-box;
  border-radius: 6px;
  position: fixed;
  bottom: 0;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  transition: all 300ms ease-in-out;
  box-shadow: var(--el-shadow);
  z-index: 10;
}

.fileSelect.assetUploader {
  transform: translate(-50%, 7px);
}

.fileSelect.hidden {
}

.fileSelectNoClick {
}

.fileSelect.showFileSelect {
  opacity: 1;
}

.dropArea,
.dropArea.active {
  transition: background 250ms ease-in-out;
}

.dropArea.active {
  /*background: #eaf1f8;*/
}

.dropArea {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100%;
  box-sizing: border-box;
  border-radius: 4px;
}

.dropArea:hover {
  cursor: pointer;
}

.dropArea.active {
  opacity: 1;
}

.dropAreaNoClick {
}

.dropAreaNoClick:hover {
  cursor: auto;
}

.indicator {
  position: relative;
  text-align: center;
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 800px;
  height: 410px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.indicator > p {
  margin-top: 10px;
  width: 270px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
}

.uploadIndicator {
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.close svg {
  transform: scale(1.4);
}

.close svg path:first-child {
  fill: #7d83a4;
}
