:global(.el-studio__canvas) {
  margin: auto;
  position: relative;
}

.canvasWrapper {
  padding-top: 50px;
}

.canvas {
}

.studio {
}

.contextMenu {
  position: absolute;
  width: 200px;
}

.colorPicker {
  position: absolute;
}

.toolbar {
  background: #fff;
  box-shadow: var(--el-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 108px;
  width: calc(100% - 98px);
  z-index: 5;
  min-height: 50px;
}

.toolbarInner {
  width: 100%;
  display: flex;
  padding: 0 12px;
  justify-content: space-between;
}
.toolbarLeft {
  display: flex;
  align-items: center;
}
.toolbarMid {
  display: grid;
  grid-auto-columns: 50px;
  grid-auto-flow: column;
  grid-column-gap: 1px;
}
.toolbarRight {
  display: flex;
  align-items: center;
}

.destination {
  margin-right: auto;
}

.destination img {
  height: 25px;
}

.tool {
  cursor: pointer;
  width: 50px;
  height: 50px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tool button {
  background: transparent;
  border: none;
}

.tool svg {
  width: 40px;
}

.tool:hover {
  transform: translateY(-2px);
}

.tool,
.tool:hover {
  transition: all 200ms ease-in-out;
  transition-property: opacity, transform;
}

.activeTool {
  background: #0075ff;
  /*transform: translateY(-3px);*/
}

.activeTool path {
  fill: white;
  stroke: white;
}

.imageSpinner {
  z-index: 5;
  top: 350px;
  left: 50%;
  position: absolute;
}

.invisible {
  opacity: 0;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.activeUsers {
  display: flex;
  align-items: center;
}

.activeAvatar {
  position: relative;
}

.inviteUser {
  margin-left: 2px;
  display: flex;
}

.syncToJiraPanel {
  position: fixed;
  top: 85px;
  width: 500px;
  height: 150px;
  background: white;
  z-index: 5;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 14px;
  border: 1px solid #e5e6e7;
  box-shadow: var(--el-shadow);
  border-radius: 0 0 12px 12px;
}

.syncToJiraActions {
  display: flex;
  justify-content: flex-end;
}

:global(.studio-extra-options) {
  display: flex;
}

:global(.studio-extra-options .my-menu) {
  top: -17px;
}

.chevron {
  position: absolute;
  right: 42px;
  top: 50%;
  z-index: 99;
  transform: translateY(-50%);
}

.chevron img,
.chevronLeft img {
  width: 20px;
}

.chevronLeft {
  composes: chevron;
  left: 120px;
  right: unset;
}

.activeProfileImage {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.addAsset {
  color: red;
}

:global([class*="addAsset"] .bp3-menu-item-label) {
  width: 100%;
}

:global([class*="addAsset"] .bp3-fill.bp3-text-overflow-ellipsis) {
  display: none;
}

.addAssetInner {
  display: flex;
  justify-content: space-between;
  color: rgb(24, 32, 38);
}

.addAssetInner img {
  height: 14px;
  margin-right: 11px;
}

.addAssetInner * {
  display: flex;
  align-items: center;
}
