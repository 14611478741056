.taskModal {
  /*opacity: 0;*/
  width: 1050px;
  height: 500px;
  background: #fffffff0;
  position: absolute;
  top: 50%;
  z-index: 50;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  box-shadow: 0px 0px 12px rgb(125 125 125 / 70%);
  border-radius: 5px;
}

.taskModalHeader {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: -5px;
  top: 4px;
}

.taskModalInner {
  border-radius: 15px;
  height: 100%;
}
.taskModalBody {
  height: 100%;
}

.modalClose {
  width: 35px;
}

.taskWithImage {
  display: grid;
  grid: auto/540px 1fr;
  height: 100%;
}

.image {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 440px;
  border-bottom: 1px solid #e5e6e7;
}

.image img {
  width: 100%;
  max-height: 412px;
  object-fit: contain;
  border-radius: 5px;
  border: 1px solid #e5e6e7;
}

.task {
  padding: 17px 24px 12px 25px;
  border-left: 1px solid #e5e6e7;
  max-height: 440px;
  overflow: auto;
  background: transparent;
  border-bottom: 1px solid #e5e6e7;
}

.task > div > div {
  background: transparent;
}

.footerButtons {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 7px;
  right: 12px;
}
