.avatar {
  width: 50px;
  background: #ccccff;
  border-radius: 50%;
  height: 50px;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
}

.avatar svg {
  width: 40px;
}
