.wrapper {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 450px;
  background: #fff;
  z-index: 55;
  height: 100%;
  transition: all 900ms cubic-bezier(0.31, 0.59, 0.07, 1);
  transition-property: transform;
  box-shadow: var(--el-shadow);
}

.open {
  transform: translateX(0);
}

.closed {
  transform: translateX(450px);
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d5e3f0;
  padding: 10px;
}

.toggles {
  display: flex;
}

.closeWrapper {
}

.inner {
  padding: 0;
}

.block {
  width: 100%;
  min-height: 165px;
  box-shadow: var(--el-shadow);
  padding: 12px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  border: 1px solid #d5e3f0;
}

div .block:not(:last-child) {
  margin-bottom: 24px;
}

.block,
.block:hover {
  transition: all 200ms ease-in-out;
  transition-property: transform, background;
}

.block:hover {
  cursor: pointer;
  transform: scale(1.02);
  background: #f1f6f9;
}

.blockActive {
  transform: scale(1.02);
  background: #f1f6f9;
}

.blockContent {
  max-height: 126px;
  overflow: auto;
  display: flex;
}

.blockNumber {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-start;
  margin-right: 12px;
  position: absolute;
  transform: translateX(5px);
}

.itemText {
  padding-left: 48px;
}

.number {
  width: 30px;
  height: 30px;
  background: black;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
}

.blockFooter {
  border-top: 1px solid #d5e3f0;
  margin-top: auto;
  padding-top: 11px;
  text-align: right;
}

.newJiraTicketButton {
  opacity: initial;
}

.newJiraTicketButton[disabled] img {
  opacity: 0.5;
}

.closeButton {
  background: transparent;
  border: none;
}

.closeButton:active,
.closeButton:focus {
  outline: 0;
}

.closeButton,
.closeButton:hover {
  transition: all 250ms ease-in-out;
  transition-property: opacity, transform;
}

.closeButton:hover {
  cursor: pointer;
  opacity: 0.9;
  transform: translateY(-3px);
}

.closeButton img {
  width: 30px;
}

.scroll {
  max-height: calc(100vh - 55px);
  overflow: auto;
  padding: 24px;
  padding-top: 7px;
  padding-bottom: 90px;
}

.extraTasksOptions {
  position: sticky;
  top: -7px;
  background: #fff;
  z-index: 5;
  margin-bottom: 10px;
  padding: 5px;
  display: flex;
}

.plug {
  color: #0075ff;
}

.selectProjects {
  position: relative;
  z-index: 10;
  width: calc(100% - 12px);
  margin: 7px;
}
