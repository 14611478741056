.notAllowed {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -30px;
}

.confirmImage {
  margin-right: 40px;
  height: 241px;
}

.notAllowed h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 43px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.notAllowed h1 img {
  height: 26px;
  margin-left: 10px;
}

.body {
  width: 487px;
}

.body p {
  line-height: 1.5;
}

.body button {
  margin-top: 20px;
}
