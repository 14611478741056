@value h2, ctrl2 from "common/type.module.css";

.box {
  width: 100%;
  min-height: 359px;
  background: #ffffff;
  box-shadow: var(--el-shadow);
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e3e6;
  /*user-select: none;*/
}

.box,
.design {
  border-radius: 10px;
}

.design {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  padding: 10px;
}

.design {
  position: relative;
  width: 100%;
  height: 273px;
  background-color: #f8f8f8;
  text-align: center;
}

div .option:not(:last-child) {
  margin-right: 10px;
}

.option path {
  fill: white;
}

.option div {
  display: flex;
  align-items: center;
}

.options,
.options:hover {
  transition: all 200ms ease-in-out;
}

.options:hover {
  opacity: 1;
}

.options {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 51, 111, 0.7);
  width: 100%;
  height: 100%;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;

  display: flex;
  flex-direction: column;
}

.optionsActive {
  opacity: 1;
}

.optionsDone {
  opacity: 0;
}

.viewHidden {
  opacity: 0;
}

.hidden.hidden {
  opacity: 0;
}

.visible.visible {
  opacity: 1;
}

.noDisplay.noDisplay {
  display: none;
}

.display {
  display: flex;
}

.viewVisible {
  opacity: 1;
}

.optionsHeader {
  min-height: 50px;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px 0 14px;
  align-items: center;
}

.optionsHeader img {
  width: 22px;
}

.optionsHeader img:not(:last-child) {
  margin-right: 8px;
}

:global([class^="design-preview_optionsHeader"] .el-spinner:before) {
  border: solid 2px hsl(0deg 0% 100% / 16%);
  border-bottom-color: #fff;
  margin-top: 10px;
}

.optionsMain {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.optionsMain a {
  text-decoration: none;
}

.name {
  composes: h2;
  text-align: center;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
}

.progress {
  margin-top: 8px;
}

.design > img {
  width: 100%;
  height: 274px;
  object-fit: contain;
}

.footer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.updatedAt {
  composes: ctrl2;
  color: var(--dark-navy);
  margin-top: 8px;
}

.check:hover {
  cursor: pointer;
}
