.seatModal {
  position: absolute;
  top: 50%;
  z-index: 50;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 23px 20px 0 20px;
  box-shadow: 0px 0px 12px rgb(125 125 125 / 70%);
  background: #fff;
  border-radius: 5px;
  width: 533px;
  height: 343px;

  display: flex;
  flex-direction: column;
}

.seatModal h1 {
  margin-bottom: 18px;
}

.footerButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  top: -8px;
  margin-top: auto;
}

.seats {
  margin-bottom: 20px;
  margin-top: 41px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.seats p {
  margin-top: 10px;
  margin-bottom: 10px;
}

.withInfo {
  display: flex;
  align-items: center;
}

.withInfo span {
  display: flex;
  align-items: center;
}

.withInfo img {
  height: 15px;
  margin-left: 5px;
}

.priceValue {
  /*  font-family: SF Pro Display; */
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 57px;
  color: #0075ff;
  position: relative;
  top: 2px;
}

.counter {
  display: flex;
  align-items: center;
  width: 160px;
  height: auto;
  justify-content: space-between;
}

.counter input {
  min-width: unset;
  text-align: center;
  width: 60px;
  font-size: 16px;
  box-shadow: var(--el-shadow);
}

.counter button {
  border-radius: 50%;
  background-color: #f7f7f7;
  width: 40px;
  height: 40px;
  padding: 1px;
  font-size: 22px;
  color: #868686;
}
