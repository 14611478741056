.error {
  width: 565px;
  margin: 0 auto;
  padding-top: 167px;
}

.error h2 {
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 50px;
  margin-bottom: 20px;
  color: #000;
}

.error p {
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 16px;

  color: #7d83a4;
}

.error button {
  min-width: 150px;
  text-emphasis: center;
  justify-content: center;
}
