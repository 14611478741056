.jiraSyncModal {
  position: absolute;
  top: 50%;
  z-index: 50;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 23px 20px 0 20px;
  box-shadow: 0px 0px 12px rgb(125 125 125 / 70%);
  background: #fff;
  border-radius: 5px;
  width: 600px;
  min-height: 420px;
  display: flex;
  flex-direction: column;
}

.footerButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: -8px;
  margin-top: auto;
}

.buttonGroup {
  display: flex;
  align-items: center;
}

.grid > *:not(:last-child) {
  margin-bottom: 15px;
}

.close {
  position: absolute;
  top: 12px;
  right: 12px;
}

.check {
}

.check svg {
  width: 60px;
  height: 60px;
}

.confirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 105px;
}

.confirm p {
  margin-top: 32px;
  width: 347px;
  text-align: center;
}

.figmaGuide {
  width: 100%;
  margin-bottom: 14px;
}

.graphic {
  text-align: center;
  padding-top: 50px;
}

.graphic img {
  width: 220px;
}

.previewInner {
  position: relative;
  top: 12px;
}

.preview {
  position: relative;
  height: 329px;
  top: 10px;
}

.previewImage {
  width: 230px;
  height: 137px;
  object-fit: contain;
  border-radius: 5px;
  padding: 4px;
  border: 1px solid #e5e6e7;
  position: absolute;
  background: white;
}

.extraPreview {
  composes: previewImage;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.extraPreview span {
  font-size: 15px;
  border-radius: 50%;
  background: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e6e7;
  color: #848484;
  font-weight: 400;
}

.toCreate {
  position: relative;
  top: 42px;
}

.toCreate p,
.toUpdate p {
  position: absolute;
  top: -26px;
}

.toUpdate {
  position: relative;
  left: 291px;
  top: 42px;
}
