.imageModal {
  position: absolute;
  top: 50%;
  z-index: 50;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 23px 20px 0 20px;
  box-shadow: 0px 0px 12px rgb(125 125 125 / 70%);
  background: #fff;
  border-radius: 5px;
  width: 485px;
  min-height: 225px;
  display: flex;
  flex-direction: column;
}

.imageModal h1 {
  margin-bottom: 18px;
  text-align: center;
}

.footerButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  top: -8px;
  margin-top: auto;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 14px;
  border: 1px dashed #e5e6e7;
  padding: 35px 20px;
  margin-bottom: 20px;
  border-radius: 6px;
}

.avatar svg {
  width: 80px;
}

.avatar button {
  margin-top: 12px;
  border: 1px solid #e5e6e7;
  padding: 5px;
  border-radius: 5rem;
  padding: 5px 12px;
  color: #7d83a4;
  font-size: 12px;
}

.invalidImage {
  color: red;
}

.avatarWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.avatarWrapper,
.avatarWrapper:hover {
  transition: opacity 150ms ease-in-out;
}

.avatarWrapper:hover {
  cursor: pointer;
  opacity: 0.8;
}

.avatarWrapper:focus {
  outline: 0;
}

.theImage {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.newImageBg {
}
