@value ctrl3 from "common/type.module.css";
@value outline from 'common/button.module.css';

.account {
  width: 100%;
  height: 100%;
  margin: 0;
  background: #f4f7f9;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  padding: 16px 92px;
  justify-content: space-between;
  position: sticky;
  top: 0;
  box-shadow: var(--el-shadow);
  z-index: 1;
  background: #fff;
}

.headerRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerRight p {
  color: #7d83a4;
  font-weight: 500;
}

.headerButton {
  composes: outline;
  border: 1px solid #0075ff;
  color: #0075ff;
  font-weight: 500;
  padding: 8px 46px;
  margin-left: 24px;
}

.headerButton:hover {
  background: #0075ff;
  color: #fff;
}

.logo {
  position: relative;
}

.beta {
  display: block;
  position: absolute;
  right: -28px;
  top: -4px;
  text-transform: uppercase;
  font-size: 8px;
  background: #0275ff;
  border-radius: 10em;
  color: #fff;
  padding: 2px 6px;
}

.logo,
.logo:hover {
  transition: all 200ms ease-in-out;
  transition-property: opacity;
}

.logo:hover {
  opacity: 0.8;
}

.message {
  width: 660px;
  margin: 115px auto 0;
  text-align: center;
}

.message h1 {
  color: #000;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 52px;
  margin-bottom: 6px;
}

.message p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #7d83a4;
}

.loginOptions {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginButtons {
  display: flex;
}

.loginButtons .loginButton:nth-child(1) {
  /*border-right: none;*/
  /*border-radius: 7px 0 0 7px;*/
  border-radius: 7px;
}

/*.loginButtons .loginButton:nth-child(2) {
  border-radius: 0 7px 7px 0;
}
*/
.loginButton {
  min-width: 228px;
  min-height: 54px;

  background: #eaf1f8;
  border: 1.21667px solid #d5e3f0;
  box-sizing: border-box;
  /*border-radius: 0px 7.3px 7.3px 0px;*/

  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #0075ff;

  display: flex;
  align-items: center;
  justify-content: center;
}

.loginButtons a:hover {
  text-decoration: none;
  color: hsl(212deg 100% 60%);
}

.loginButtons a,
.loginButtons a:hover {
  transition: all 200ms ease-in-out;
  transition-property: color;
}

.loginButton img {
  margin-right: 8px;
}

.separator {
  margin: 25px auto 0 auto;
  width: 537px;
  text-align: center;
  display: flex;
  align-items: center;
}

.sep {
  height: 1px;
  width: 246px;
  background: #d5e3f0;
}

.separator p {
  margin: 0 12px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #7d83a4;
}

.form {
  width: 448px;
  margin: 0 auto;
}

.terms {
  width: 432px;
  margin: 0 auto;
  margin-top: 24px;
}

.terms p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */
  color: #7d83a4;
}

.footer {
  z-index: 1;
  margin-top: 30px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
  padding-bottom: 24px;
}

.footer p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  text-align: center;
  color: #7d83a4;
}

.flag,
.planet,
.fuzzy,
.circles {
  pointer-events: none;
}

.flag {
  position: absolute;
  bottom: -8px;
  right: 211px;
}

.flag img {
  width: 517px;
}

.planet {
  position: fixed;
  bottom: 125px;
  right: 0;
}

.planet img {
  width: 529px;
}

.fuzzy {
  position: fixed;
  bottom: -5px;
  right: 756px;
}

.fuzzy img {
  width: 80px;
}

.circles {
  position: fixed;
  left: 152px;
  bottom: 395px;
}

.circles img {
  width: 373px;
}
