.org {
  display: grid;
  grid: auto / 320px 1fr;
  grid-column-gap: 20px;
}

.orgDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px solid hsl(230deg 17% 93%);
  box-shadow: var(--el-shadow2);
  padding: 30px;
  min-height: 340px;
  max-height: 340px;
}

.orgMembers {
}

.orgLogo {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 26px;
  border: 2px solid hsl(225deg 15% 96%);
  border-radius: 50%;
  padding: 12px;
}

.orgLogoCover {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.orgLogo img {
  height: 100px;
}

.team {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
}

.teamImg {
  flex: 1;
  background: #ffffffde;
  backdrop-filter: blur(6px);
  display: flex;
  flex-direction: column;
}

.teamImg img {
  height: 284px;
  margin-bottom: 20px;
}

.teamInner {
  position: relative;
  top: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.teamIntro {
  margin-bottom: 12px;
  width: 419px;
  text-align: center;
}

.hide {
  display: none;
}

.changePicture {
  position: absolute;
  bottom: -8px;
  right: 0;
}

.changePicture button {
  height: 50px;
}

.changePicture img {
  height: 35px;
}

.changeLogoModal {
}

.delete {
  margin-top: 22px;
}

.addInput {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.addInput input {
  flex: 0.5;
  margin-right: 17px;
}

.seatLimit {
  padding: 26px;
  text-align: left;
  box-shadow: var(--el-shadow2);
  border-radius: 5px;
  border-top: 3px solid #fbbb00;
  margin: 0 auto 35px;
  width: 100%;
}
