.studioHints {
  position: absolute;
  width: 450px;
  z-index: 10;
  right: 20px;
  bottom: 20px;
  background: rgb(255 255 255 / 84%);
  box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
  backdrop-filter: blur(16px);
  border-radius: 6px;
  overflow: auto;
  padding: 24px 20px 20px;
}

.studioHints rect {
  fill: #000;
}

.hintsAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.hint {
  width: 100%;
  min-height: 111px;
  display: flex;
  padding: 20px 16px;
  border-left: 6px solid #0075ff;
  background: #fff;
  box-shadow: 0px 0px 12px rgba(207, 219, 234, 0.7);
  border-radius: 6px;
}

.hint video {
  margin-top: 8px;
  border-radius: 3px;
}

.hint h2 {
  margin-bottom: 5px;
}

.hint > img {
  margin-right: 11px;
}

.studioHints > .hint:not(:last-child) {
  margin-bottom: 20px;
}
